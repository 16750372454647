import React, { useMemo } from 'react';
import { Typography, Badge, Button, Tooltip } from 'antd';
import styled from 'styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectors } from '@amplement/backend-connector';
import { EllipsisOutlined } from '@ant-design/icons';

import Icon from 'components/Shared/Common/Icon';
import Row from 'components/CallBuilder/Row/Row';
import { RoomLabel, getIconName, getRoomLabelId } from 'components/Shared/Notification/Room';
import Link from 'components/Shared/Common/Link';
import CallButton from 'components/CallBuilder/DefaultResults/style';

import { colors } from 'common/themes/Colors';

import { StyledCard, StyledCol } from 'screens/Dashboard/styles';

import { NOTIFICATION_CATEGORIES, NOTIFICATION_ROOM_TYPES } from 'const/notification';

import useNotifications, { NotificationData } from 'hooks/useNotifications';
import { getCurrentClientIdSelector, getCurrentUserIdSelector } from 'selectors/user';
import enrichMembers from 'services/members';

import { getUrl } from 'utils/url';

const { Title } = Typography;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    h4{
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const StyledCardContainer = styled(StyledCard)`
    .ant-card-body {
        padding: 0;
    }
`;

const StyledCallButton = styled(CallButton)`
    margin-right: 10px;
`;

const StyledRow = styled(Row)`
    padding: 10px;
`;

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

type MissedCallNotificationPropsType = {
    notification: NotificationData;
    _myUser: string;
    _myClient: string;
} & WrappedComponentProps;

export const MissedCallNotification = ({
    notification: {
        payload: {
            name,
            members = [],
            _sourceFeed,
            isPersistent,
            isMulticast,
            id: _room,
            caller: {
                isMyUser,
            } = {},
        },
        type,
        createdAt: date,
    },
    intl,
    _myUser,
    _myClient,
}: MissedCallNotificationPropsType): JSX.Element => {
    const status = useMemo(() => (
        <>
            <Icon iconName={getIconName(isMyUser, type, false, members)} />
            {intl.formatMessage({ id: getRoomLabelId(isMyUser, type, members) })}
        </>
    ), [isMyUser, type, members, intl]);

    const isMissed = useMemo(() => type === NOTIFICATION_ROOM_TYPES.MISSED, [type]);

    const enrichedMembers = useMemo(() => enrichMembers(_room, _myUser, _myClient)(members), [members]);

    return (
        <StyledRow
            label={(
                <RoomLabel
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    isPersistent={isPersistent}
                    members={enrichedMembers}
                    name={name}
                    className="s-recent-call-name"
                    isMulticast={isMulticast}
                    _sourceFeed={_sourceFeed}
                />
            )}
            members={enrichedMembers}
            date={date}
            status={status}
            isMissed={isMissed}
            hidePlusButton
            onAddParticipant={() => {}}
            onRemoveParticipant={() => {}}
            hasParticipant={false}
        >
            <StyledCallButton
                iconName="Phone"
                _room={_room}
                _feed={_sourceFeed}
                members={enrichedMembers}
                isPersistent={isPersistent}
            >
                <p>{intl.formatMessage({ id: 'call.call' })}</p>
            </StyledCallButton>
        </StyledRow>
    )
}

type MissedCallCardPropsType = {
    max?: number;
    callNotificationCount: number;
} & WrappedComponentProps;

export const MissedCall = ({
    intl,
    max = 50,
    callNotificationCount,
}: MissedCallCardPropsType): JSX.Element | null => {
    const _myUser = useSelector(getCurrentUserIdSelector);
    const _myClient = useSelector(getCurrentClientIdSelector);
    const { data = [] } = useNotifications({
        category: NOTIFICATION_CATEGORIES.ROOM,
        type: NOTIFICATION_ROOM_TYPES.MISSED,
        limit: max,
        page: 1,
        ignored: undefined,
        read: false,
        notificationCount: callNotificationCount,
    });

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="ExclamationCircle" />
            <StyledTitle>
                <div className="label">
                    <Title level={4}>{intl.formatMessage({ id: 'call.callhistory.status.missedIncoming' })}</Title>
                    <Badge overflowCount={999} count={callNotificationCount} />
                </div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link
                    href={getUrl('call')}
                    className="text"
                >
                    <Tooltip
                        placement="top"
                        title={intl.formatMessage({ id: 'global.button.showMore' })}
                    >
                        <StyledButton shape="circle">
                            <EllipsisOutlined />
                        </StyledButton>
                    </Tooltip>
                </Link>
            </StyledTitle>
        </StyledContainer>
    ), [intl, callNotificationCount]);

    if (!data || !data.length) {
        return null;
    }

    return (
        <StyledCol>
            <StyledCardContainer
                maxHeight={500}
                title={cardTitle}
            >
                {data.map((notification: NotificationData) => (
                    <MissedCallNotification
                        key={notification.id}
                        _myUser={_myUser}
                        _myClient={_myClient}
                        notification={notification}
                        intl={intl}
                    />
                ))}
            </StyledCardContainer>
        </StyledCol>
    )
};

MissedCall.propTypes = {
    intl: PropTypes.object.isRequired,
    callNotificationCount: PropTypes.number.isRequired,
    max: PropTypes.number,
};

MissedCall.defaultProps = {
    max: 50,
};

const mapStateToProps = state => ({
    callNotificationCount: selectors.notifications.getUnreadNotificationCountByCategorySelector(state, NOTIFICATION_CATEGORIES.ROOM) || 0,
});

export default connect(mapStateToProps)(injectIntl(MissedCall as React.FC<MissedCallCardPropsType>));
