import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Row from 'components/CallBuilder/Row/Row';
import CallButton from 'components/CallBuilder/DefaultResults/style';
import Button from 'components/Shared/Common/Button';
import ButtonClick from 'components/Shared/Common/Button/ButtonClick';

import { NAVIGATION, MAX } from 'components/CallBuilder/constants';
import { CommonListProps, ListProps, MaxSipReachedProps } from 'components/CallBuilder/types';

import { BookmarkResult } from 'hooks/useSearch';

import { PRESENCE_STATUSES } from 'const/user';
import { getUrl } from 'utils/url';

const { Text, Paragraph } = Typography;

const StyledParagraph = styled(Paragraph)`
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
`;

type BookmarkProps = {
    bookmark: BookmarkResult;
} & WrappedComponentProps & CommonListProps & MaxSipReachedProps;

const Bookmark = injectIntl(({
    bookmark,
    inputValue,
    onAddParticipant,
    onCloseModal,
    onRemoveParticipant,
    intl,
    maxSipReached,
    isSipDisconnected,
    showDirectCall,
}: BookmarkProps): JSX.Element => {
    const translation = useMemo(() => ({ call: intl.formatMessage({ id: 'call.call' }) }), [intl]);

    const handleAddBookmark = useCallback(() => {
        if (inputValue.find((item) => item?.data?.phoneNumber === bookmark?.data?.phoneNumber)) {
            return;
        }

        onAddParticipant?.({
            ...bookmark,
            resultType: NAVIGATION.BOOKMARKS,
            label: bookmark.data.label,
            value: bookmark.data.phoneNumber,
            phoneNumber: bookmark.data.phoneNumber,
        });
    }, [onAddParticipant, inputValue, bookmark]);

    const handleRemoveParticipant = useCallback(() => {
        onRemoveParticipant?.({ ...bookmark, label: bookmark.data.label, value: bookmark.data.phoneNumber }, `data.phoneNumber`);
    }, [onRemoveParticipant, bookmark]);

    const hasParticipant = useMemo(() => !!inputValue.find((item) => item?.data?.phoneNumber === bookmark?.data?.phoneNumber), [inputValue, bookmark]);

    return (
        <Row
            label={bookmark?.data?.label}
            phoneNumber={bookmark?.data?.phoneNumber}
            onAddParticipant={handleAddBookmark}
            onRemoveParticipant={handleRemoveParticipant}
            hasParticipant={hasParticipant}
            presenceStatus={bookmark.user?.status ?? PRESENCE_STATUSES.OFFLINE}
            disablePlusButton={maxSipReached}
            avatarUri={bookmark.user?.avatarUri}
        >
            {!inputValue.length && !isSipDisconnected && showDirectCall ? (
                <ButtonClick onClick={onCloseModal}>
                    <CallButton
                        phoneNumber={bookmark?.data?.phoneNumber}
                        prefix
                        iconName="Phone"
                    >
                        <p>{translation.call}</p>
                    </CallButton>
                </ButtonClick>
            ) : null}
        </Row>
    )
});

type BookmarkListProps = ListProps & WrappedComponentProps & MaxSipReachedProps;

const BookmarkList = ({
    max,
    maxSipReached,
    isLoading,
    inputValue,
    isSipDisconnected,
    searchResults: { [NAVIGATION.BOOKMARKS]: bookmarks } = {},
    onAddParticipant,
    onRemoveParticipant,
    intl,
    onCloseModal,
    showDirectCall,
}: BookmarkListProps): JSX.Element | JSX.Element[] => {
    const { results = [] } = bookmarks || {};

    const history = useHistory?.();
    const handleClick = useCallback(() => {
        history?.push(getUrl('businessContacts'));
        onCloseModal?.();
    }, [history, onCloseModal]);

    if (isLoading) {
        return <LoadingOutlined />;
    }

    if (!results || !results.length) {
        if (!showDirectCall) {
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            return <Text>{intl.formatMessage({ id: 'callbuilder.bookmarks.emptyList' })}</Text>;
        }

        return (
            <StyledParagraph style={{ textDecoration: 'underline' }}>
                <Button onClick={handleClick}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Text>{intl.formatMessage({ id: 'callbuilder.bookmarks.emptyList' })}</Text>
                </Button>
            </StyledParagraph>
        );
    }

    return results.slice(0, max).map(bookmark => (
        <Bookmark
            key={bookmark.id}
            isSipDisconnected={isSipDisconnected}
            maxSipReached={maxSipReached}
            showDirectCall={showDirectCall}
            inputValue={inputValue}
            onCloseModal={onCloseModal}
            onAddParticipant={onAddParticipant}
            onRemoveParticipant={onRemoveParticipant}
            bookmark={bookmark}
        />
    ));
};

BookmarkList.propTypes = {
    max: PropTypes.number,
    maxSipReached: PropTypes.bool,
    showDirectCall: PropTypes.bool,
    isSipDisconnected: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCloseModal: PropTypes.func,
    inputValue: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            resultType: PropTypes.string,
            phoneNumber: PropTypes.string,
            data: PropTypes.shape({
                label: PropTypes.string,
                phoneNumber: PropTypes.string,
            }),
            _user: PropTypes.string,
        })
    ).isRequired,
    searchResults: PropTypes.shape({
        [NAVIGATION.BOOKMARKS]: PropTypes.shape({
            results: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.string.isRequired,
                data: PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    phoneNumber: PropTypes.string.isRequired,
                }).isRequired,
            })).isRequired,
            paging: PropTypes.shape({
                total: PropTypes.number.isRequired,
                from: PropTypes.number.isRequired,
                to: PropTypes.number.isRequired,
            }).isRequired,
            error: PropTypes.string,
        }),
    }),
    onAddParticipant: PropTypes.func,
    onRemoveParticipant: PropTypes.func,
};

BookmarkList.defaultProps = {
    maxSipReached: false,
    showDirectCall: false,
    isSipDisconnected: false,
    max: MAX,
    onCloseModal: undefined,
    isLoading: false,
    onAddParticipant: undefined,
    onRemoveParticipant: undefined,
    searchResults: {},
};

export default injectIntl(BookmarkList as React.FunctionComponent<BookmarkListProps>);
