import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Grid, Result } from 'antd';
import { SoundTwoTone, VideoCameraTwoTone } from '@ant-design/icons';
import { ResultStatusType } from 'antd/es/result';
import { connect } from 'react-redux';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DeviceManager from 'components/Room/Helpers/DeviceManager';
import Icon from 'components/Shared/Common/Icon';
import StyledCallButton from 'components/CallBuilder/DefaultResults/style';
import { getAvailableDevices, getRoomSettings } from 'selectors/roomSettings';
import Devices from 'components/Listeners/Devices/container';

import { colors } from 'common/themes/Colors';

import { StyledCard } from 'screens/Dashboard/styles';

import Link from 'components/Shared/Common/Link';
import Button from 'components/Shared/Common/Button';

import { getUrl } from 'utils/url';

const { Title, Paragraph, Text } = Typography;

const { useBreakpoint } = Grid;

const StyledIcon = styled(Icon)`
    svg {
        width: 26px;
        height: 26px;
    }
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.div`
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    .label{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    h4{
        font-weight: 400 !important;
        margin-right: 10px;
        margin-bottom: 0;
    }
`;

const StyledCardWrapper = styled(StyledCard)`
    .ant-card-body {
        margin-top: 18px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    display: flex;
    align-items: center;
    margin-bottom: 0;
`;

const StyledText = styled(Text)`
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export type SettingsType = {
    audioinput: string;
    audiooutput: string;
    videoinput: string;
    skipNextTime: number;
};

export type DevicesType = {
    deviceId: string;
    groupId: string;
    kind: string;
    label: string;
};

type DevicesCardProps = {
    settings: SettingsType;
    devices: DevicesType[] | [];
} & WrappedComponentProps;

export const DevicesCard = ({
    intl,
    settings,
    devices,
}: DevicesCardProps): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState<{ message?: string, status?: ResultStatusType } | null>(null);
    const { lg, xl, xxl } = useBreakpoint();

    const isLargeScreen = useMemo(() => lg || xl || xxl, [lg, xl, xxl]);

    const checkPermissions = useCallback(async () => {
        try {
            const { state: audioPerms } = await DeviceManager.getPermissionStatus('microphone');
            const { state: videoPerms } = await DeviceManager.getPermissionStatus('camera');
            if (audioPerms === 'granted' && videoPerms === 'granted') {
                setErrorMessage(null);
            }

            if (audioPerms === 'prompt' || videoPerms === 'prompt') {
                setErrorMessage({ status: 'warning', message: 'rtc.modal.requestPermission.all.content' });
            }

            if (audioPerms === 'denied') {
                setErrorMessage({ status: 'error', message: 'rtc.modal.permissionDenied.content' });
            }
        } catch (e) {
            setErrorMessage({ status: 'error', message: 'error.default' });
        }
    }, []);

    const cardTitle = useMemo(() => (
        <StyledContainer>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <StyledIcon bgColor={colors.black} iconName="Headphone" />
            <StyledTitle>
                <div className="label">
                    <Title level={4}>
                        {intl.formatMessage({ id: 'configurate.title.devices' })}
                    </Title>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link
                    href={getUrl('deviceConfiguration')}
                    className="text"
                >
                    <StyledCallButton>
                        <Button iconName="Settings">
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            {isLargeScreen && <p>{intl.formatMessage({ id: 'dashboard.parameters.configuration' })}</p>}
                        </Button>
                    </StyledCallButton>
                </Link>
            </StyledTitle>
        </StyledContainer>
    ), [intl, isLargeScreen]);

    const selectedDevices = useMemo(() => {
        const result: {
            audioinput?: string;
            audiooutput?: string;
            videoinput?: string;
        } = {
            audioinput: undefined,
            audiooutput: undefined,
            videoinput: undefined,
        }

        if (settings.audioinput) {
            result.audioinput = devices.find((device) => device.deviceId === settings.audioinput)?.label;
        }

        if (settings.audiooutput) {
            result.audiooutput = devices.find((device) => device.deviceId === settings.audiooutput)?.label;
        }

        if (settings.videoinput) {
            result.videoinput = devices.find((device) => device.deviceId === settings.videoinput)?.label;
        }

        return result;
    }, [settings, devices]);

    useEffect(() => {
        checkPermissions();
    }, [checkPermissions]);

    if (errorMessage?.message) {
        return (
            <StyledCardWrapper title={cardTitle}>
                <Devices />
                <Result
                    status={errorMessage?.status}
                    subTitle={`${intl.formatMessage({ id: errorMessage?.message })} ${intl.formatMessage({ id: 'rtc.reminder.devices' })}`}
                />
            </StyledCardWrapper>
        )
    }

    if (!(selectedDevices.audioinput || selectedDevices.audiooutput || selectedDevices.videoinput)) {
        return (
            <>
                <Devices />
                <StyledCardWrapper title={cardTitle}>
                    <Result
                        status="info"
                        subTitle={intl.formatMessage({ id: 'configurate.devices.noDevices' })}
                    />
                </StyledCardWrapper>
            </>
        );
    }

    return (
        <>
            <Devices />
            <StyledCardWrapper title={cardTitle}>
                {selectedDevices?.audioinput && (
                    <StyledParagraph>
                        <Icon iconName="Mic" bgColor={colors.success} />
                        <StyledText>
                            {selectedDevices?.audioinput}
                        </StyledText>
                    </StyledParagraph>
                )}
                {selectedDevices?.audiooutput && (
                    <StyledParagraph>
                        <SoundTwoTone twoToneColor={colors.success} />
                        <StyledText>
                            {selectedDevices?.audiooutput}
                        </StyledText>
                    </StyledParagraph>
                )}
                {selectedDevices?.videoinput && (
                    <StyledParagraph>
                        <VideoCameraTwoTone twoToneColor={colors.success} />
                        <StyledText>
                            {selectedDevices?.videoinput}
                        </StyledText>
                    </StyledParagraph>
                )}
            </StyledCardWrapper>
        </>
    );
};

DevicesCard.propTypes = {
    intl: PropTypes.object.isRequired,
    settings: PropTypes.shape({
        audioinput: PropTypes.string.isRequired,
        audiooutput: PropTypes.string.isRequired,
        videoinput: PropTypes.string.isRequired,
        skipNextTime: PropTypes.number.isRequired,
    }),
    devices: PropTypes.array,
};

DevicesCard.defaultProps = {
    devices: [],
    settings: {
        audioinput: '',
        audiooutput: '',
        videoinput: '',
        skipNextTime: 0,
    },
};

const mapStateToProps = (state) => ({
    settings: getRoomSettings(state),
    devices: getAvailableDevices(state),
});

export default connect(mapStateToProps)(injectIntl(DevicesCard as React.FC<DevicesCardProps>));
